import React from 'react';
import { useSelector } from 'react-redux';
import {
    AppContent,
    baseUnit,
    ErrorBoundary,
    MEDIA_BREAKPOINTS,
    OneOTCThreeHCHorizontal,
    ResizingCarousel,
    SectionWithTitle,
    TwoOneTwoArticleList,
    Headline,
    LinkImage,
    FourLinkImageLayout,
    WideScrollableArticleList,
    OneHCThreeVC,
    ThreeVC,
    ScrollableLinkImages,
    SwimsuitPlayer,
    LinkWithButtonStyle,
} from 'mm-ui-components';
import { Store } from 'redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme } from '../../components/connectedComponents/Theme';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { SECTION_NAMES } from '../templates.const';
import { Page } from '../Page';
import {
    getMetadataDescription,
    getMetadataImage,
    getMetadataTitle,
    getHreflangData,
    getSectionImagesWithAdditionalLinksByKey,
    getSectionTitleByKey,
    getSectionArticlesByKey,
    getSectionImageLinks,
    getSectionBanner,
    getSectionBannerLink,
    getMoreButtonTextByKey,
    getFeaturedArticle,
} from '../../store/template/homePage/homePageGoa/homePageGoa.selectors';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { AdditionalStyleSheet } from '../templates.utils';
import { getUseFormatMinutesHoursAgo } from '../../store/config/config.selectors';

interface HomePageGoaProps {
    store: Store<AppState, AppActions>;
    errorProvider: ErrorReporter;
}

const getArticlesInTemplate = (state: AppState) => {
    const section4Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION4)(state) || [];
    const section6Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION6)(state) || [];
    const section7Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION7)(state) || [];
    const section9Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION9)(state) || [];
    const featuredArticle = getFeaturedArticle(SECTION_NAMES.TOP_SECTION)(state) || [];
    const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state);
    const section11Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION11)(state) || [];
    const section12Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION12)(state) || [];
    return [
        ...featuredArticle,
        ...topSectionArticles,
        ...section11Articles,
        ...section12Articles,
        ...section4Articles,
        ...section6Articles,
        ...section7Articles,
        ...section9Articles,
    ];
};


export const HomePageGoaComponent: React.FunctionComponent<HomePageGoaProps> = ({ store, errorProvider }) => {
    useMeasureHydrateTime();
    return (
      <Page store={store} errorProvider={errorProvider}>
        <ErrorBoundary>
          <HomePageGoaContentConnected />
        </ErrorBoundary>
      </Page>
    );
};

const createStyle = () => StyleSheet.create({
    sectionsWrapper: {
        [MEDIA_BREAKPOINTS.large]: {
            gridRowGap: `${baseUnit * 2}px`,
        },
        [MEDIA_BREAKPOINTS.medium]: {
            gridRowGap: `${baseUnit * 2}px`,
        },
        [MEDIA_BREAKPOINTS.small]: {
            gridRowGap: `${baseUnit * 2}px`,
        },
        display: 'grid',
        gridTemplateColumns: '1fr',
        marginTop: `${baseUnit * 2}px`,
        marginBottom: `${baseUnit * 0.5}px`,
        justifyItems: 'center',
    },
    title: {
      marginBottom: '20px',
    },
    imagesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
    },
});

const sectionsStyle = StyleSheet.create({
  style: {
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `0 ${baseUnit}px`,
    },
  },
}) as AdditionalStyleSheet;

const headlineStyle = StyleSheet.create({
  style: {
    marginBottom: '20px',
    paddingLeft: '10px',
    [MEDIA_BREAKPOINTS.medium]: {
      paddingRight: '10px',
    },
  },
}) as AdditionalStyleSheet;


const fourlinkImageStyle = StyleSheet.create({
  style: {
    margin: '40px 10px',
  [MEDIA_BREAKPOINTS.medium]: {
      margin: '15px 10px',
  },
  },
}) as AdditionalStyleSheet;

const moreButtonStyle = StyleSheet.create({
  style: {
    marginTop: '20px',
    width: 'fit-content',
    alignSelf: 'center',
    textDecoration: 'none',
  },
}) as AdditionalStyleSheet;

const sectionStyle = StyleSheet.create({
  style: {
    display: 'flex',
    flexDirection: 'column',
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `0 ${baseUnit}px`,
    },
  },
}) as AdditionalStyleSheet;

const commonStyle = StyleSheet.create({
  style: {
      padding: '0 10px',
  },
}) as AdditionalStyleSheet;

const scrollStyle = StyleSheet.create({
  style: {
    [MEDIA_BREAKPOINTS.medium]: {
      marginRight: '10px',
    },
  },
}) as AdditionalStyleSheet;

export const HomePageGoaContentConnected: React.FunctionComponent = () => {
    const section1Articles = useSelector(getSectionImagesWithAdditionalLinksByKey(SECTION_NAMES.SECTION1));
    const section2Image = useSelector(getSectionBanner(SECTION_NAMES.SECTION2));
    const section2Link = useSelector(getSectionBannerLink(SECTION_NAMES.SECTION2));
    const section4DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION4));
    const section4Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION4));
    const section5DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION5));
    const section5Images = useSelector(getSectionImageLinks(SECTION_NAMES.SECTION5));
    const section5ButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.SECTION5));
    const section6DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION6));
    const section6Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION6));
    const section7DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION7));
    const section7Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION7));
    const section9DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION9));
    const section9Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION9));
    const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION));
    const featuredArticle = useSelector(getFeaturedArticle(SECTION_NAMES.TOP_SECTION)) || [];
    const section10DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION10));
    const section10Images = useSelector(getSectionImageLinks(SECTION_NAMES.SECTION10));
    const section11DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION11));
    const section11Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION11));
    const section12DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION12));
    const section12Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION12));

    const customRatios = { small: { x: 1, y: 1 }, medium: { x: 1, y: 1 }, large: { x: 1, y: 1 } };
    const articles = useSelector(getArticlesInTemplate);
    const metaTitle = useSelector(getMetadataTitle);
    const metaDescription = useSelector(getMetadataDescription);
    const pageImage = useSelector(getMetadataImage);
    const hreflangData = useSelector(getHreflangData) || [];
    const style = createStyle();
    const useFormatMinutesHoursAgo = useSelector(getUseFormatMinutesHoursAgo);

    return (
      <React.Fragment>
        <MetaTags articles={articles} title={metaTitle} description={metaDescription} pageImage={pageImage} alternateUrls={hreflangData} />
        <HeadScripts />
        <Theme>
          <AppContent>
            <NavigationLayout mainWrapperConfigThemeKey="mainContent">
              <div className={css(style.sectionsWrapper)}>
                <SectionWithTitle additionalStyle={sectionsStyle}>
                  <OneOTCThreeHCHorizontal cards={[...featuredArticle, ...topSectionArticles]} />
                </SectionWithTitle>
                <SectionWithTitle additionalStyle={sectionsStyle}>
                  <Headline title={section7DisplayName} additionalStyle={headlineStyle} />
                  <OneHCThreeVC cards={section7Articles.slice(0, 4)} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                  <ThreeVC cards={section7Articles.slice(4)} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </SectionWithTitle>
                <WideScrollableArticleList
                  cardsProps={section11Articles}
                  cardsSectionDisplayName={section11DisplayName}
                  showHeadline
                  hasBackground={false}
                />
                <WideScrollableArticleList
                  cardsProps={section12Articles}
                  cardsSectionDisplayName={section12DisplayName}
                  showHeadline
                  hasBackground={false}
                />
                <ResizingCarousel images={section1Articles} />
                <SectionWithTitle additionalStyle={sectionsStyle}>
                  <SwimsuitPlayer />
                </SectionWithTitle>
                <SectionWithTitle additionalStyle={sectionsStyle}>
                  <Headline title={section4DisplayName} additionalStyle={headlineStyle} />
                  <TwoOneTwoArticleList cards={section4Articles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </SectionWithTitle>
                <SectionWithTitle additionalStyle={sectionsStyle}>
                  <LinkImage
                    {...section2Image}
                    linkSrc={section2Link}
                    imageWidths={{
                    large: 1080 as const,
                    medium: 720 as const,
                    small: 540 as const,
                  }}
                  />
                </SectionWithTitle>
                <SectionWithTitle additionalStyle={sectionStyle}>
                  <Headline title={section5DisplayName} additionalStyle={headlineStyle} />
                  <FourLinkImageLayout linkImages={section5Images.slice(0, 4)} additionalStyle={commonStyle} />
                  <FourLinkImageLayout linkImages={section5Images.slice(4, 8)} additionalStyle={fourlinkImageStyle} />
                  <FourLinkImageLayout linkImages={section5Images.slice(8)} additionalStyle={commonStyle} />
                  <LinkWithButtonStyle href={section5ButtonText?.href || undefined} additionalStyle={moreButtonStyle}>{section5ButtonText?.text}</LinkWithButtonStyle>
                </SectionWithTitle>
                <WideScrollableArticleList
                  cardsProps={section6Articles}
                  cardsSectionDisplayName={section6DisplayName}
                  showHeadline
                />
                <SectionWithTitle additionalStyle={sectionsStyle}>
                  <Headline title={section9DisplayName} additionalStyle={headlineStyle} />
                  <OneOTCThreeHCHorizontal cards={section9Articles} />
                </SectionWithTitle>
                <SectionWithTitle additionalStyle={sectionsStyle}>
                  <Headline title={section10DisplayName} additionalStyle={headlineStyle} />
                  <ScrollableLinkImages linkImages={section10Images} customRatios={customRatios} additionalStyle={scrollStyle} />
                </SectionWithTitle>
              </div>
            </NavigationLayout>
          </AppContent>
        </Theme>
      </React.Fragment>
    );
};
